/**
 *  @desprition 配合checkIntersection_click方法进行使用，点击事件处理
 *  @dependence THREE
 *  @author xiaocuihero
 */
import * as THREE from "@/assets/three/build/three.module.js";
import Commen from '@/assets/js/Commen.js';

let isMobile = (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
isMobile = isMobile ? 2 : 1
let isOrientation = true;

class EventHandler extends THREE.EventDispatcher {

    constructor(vue) {

        super();

        let {
            scene,
            camera,
            controls,
            container,
            renderer,
            css2DRenderer
        } = vue;

        if (!scene || !camera || !controls || !container || !renderer) {
            console.log(`EventHandler call 'new' function error~ :
                scene:${scene};
                camera:${camera};
                controls:${controls};
                container:${container};
                renderer:${renderer};
            `);
        }

        this.scene = scene;
        this.camera = camera;
        this.controls = controls;
        this.container = container;
        this.renderer = renderer;
        this.css2DRenderer = css2DRenderer
        this.tem_e = {
            x: 0,
            y: 0
        };
        this.mouse = {
            x: 0,
            y: 0
        };
        this.raycaster = new THREE.Raycaster();
        this.raycaster.layers.enableAll()
        this.renderer.domElement.addEventListener('pointerdown', this.onMouseDown.bind(this), false);
        this.renderer.domElement.addEventListener('pointerup', this.onMouseUp.bind(this), false);
        this.renderer.domElement.addEventListener('dblclick', this.onDblClick.bind(this), false);
        window.addEventListener('resize', this.onWindowResize.bind(this), false);

    }

    enableMouseMove(intersectObjs) {
        this.onMouseMoveDelegate = throttle(this.onMouseMove.bind(this), 1000 / 60, 30);
        this.renderer.domElement.addEventListener('pointermove', this.onMouseMoveDelegate, false);
        this.intersectObjs = intersectObjs;
    }

    disableMouseMove() {
        if (this.onMouseMoveDelegate) {
            this.renderer.domElement.removeEventListener('pointermove', this.onMouseMoveDelegate);
        }
        this.intersectObjs = [];
    }

    onMouseDown(event) {
        this.isMouseDown = true;
        let x, y;
        if (event.changedTouches) {
            x = event.changedTouches[0].pageX;
            y = event.changedTouches[0].pageY;
        } else {
            x = event.clientX;
            y = event.clientY;
        }
        this.tem_e.x = x;
        this.tem_e.y = y;
    }

    onMouseMove(event) {
        let l = this.container.getBoundingClientRect().left;
        let t = this.container.getBoundingClientRect().top;
        let x, y;
        if (event.changedTouches) {
            x = event.changedTouches[0].pageX;
            y = event.changedTouches[0].pageY;
        } else {
            x = event.clientX;
            y = event.clientY;
        }
        this.mouse.x = ((x - l) / this.container.clientWidth) * 2 - 1;
        this.mouse.y = -((y - t) / this.container.clientHeight) * 2 + 1;
        this.checkIntersection();
        this.onMouseDrag(event);
    }

    //点击抬起事件
    onMouseUp(event) {
        this.isMouseDown = false;
        let l = this.container.getBoundingClientRect().left;
        let t = this.container.getBoundingClientRect().top;
        let x, y;
        //console.log(event);
        if (event.changedTouches) {
            x = event.changedTouches[0].pageX;
            y = event.changedTouches[0].pageY;
        } else {
            x = event.clientX;
            y = event.clientY;
        }
        this.mouse.x = ((x - l) / this.container.clientWidth) * 2 - 1;
        this.mouse.y = -((y - t) / this.container.clientHeight) * 2 + 1;
        if (this.tem_e.x == x && this.tem_e.y == y) {
            if (isMobile == 2 && isOrientation) {
                let mouse = {};
                mouse.x = ((y - t) / this.container.clientWidth) * 2 - 1;
                mouse.y = ((x - l) / this.container.clientHeight) * 2 - 1;
                this.checkIntersection_click(mouse);
            } else {
                this.checkIntersection_click(this.mouse);
            }
        }
    }
    homeClick() {
        console.log(1);
        Commen.go_cameraV(new THREE.Vector3(-7.349, -0.080, 1.612), new THREE.Vector3(-34.561, 25.720, 22.326), this.controls, this.camera, 1, 0);
    }
    // 双击
    onDblClick(event) {
        let l = this.container.getBoundingClientRect().left;
        let t = this.container.getBoundingClientRect().top;
        let x, y;
        if (event.changedTouches) {
            x = event.changedTouches[0].pageX;
            y = event.changedTouches[0].pageY;
        } else {
            x = event.clientX;
            y = event.clientY;
        }
        this.mouse.x = ((x - l) / this.container.clientWidth) * 2 - 1;
        this.mouse.y = -((y - t) / this.container.clientHeight) * 2 + 1;
        if (this.tem_e.x == x && this.tem_e.y == y) {
            if (0 == event.button) {
                this.checkIntersection_dblclick();
            } else {
                console.log(123);
            }
        }
    }

    onWindowResize() {
        this.camera.aspect = this.container.clientWidth / this.container.clientHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
        if (this.css2DRenderer) {
            this.css2DRenderer.setSize(this.container.clientWidth, this.container.clientHeight);
        }
    }

    checkIntersection_click(mouse = this.mouse) {

        this.raycaster.setFromCamera(mouse, this.camera);
        let intersects = this.raycaster.intersectObjects(this.scene.children, true);
        intersects = intersects.filter(x => x.object.visible)
        this.dispatchEvent({
            type: 'click',
            intersects
        });

        if (intersects.length > 0) {

            let { x, y, z } = intersects[0].point;
            let text = `new THREE.Vector3(${x.toFixed(3)},${y.toFixed(3)},${z.toFixed(3)}),`
            console.log(text);

            // let dir = intersects[0].point.clone().sub(this.camera.position).normalize();
            // let origin = this.camera.position.clone();
            // let length = 10000;
            // let hex = 0xffff00
            // const arrowHelper = new THREE.ArrowHelper(dir, origin, length, hex);
            // arrowHelper.name = 'arrowHelper'
            // this.scene.add(arrowHelper);

            // navigator.clipboard.writeText(text);
            // navigator.clipboard.writeText(`(${x.toFixed(3)},${y.toFixed(3)},${z.toFixed(3)})`);
        } else {

        }
    }

    checkIntersection() {

        this.raycaster.setFromCamera(this.mouse, this.camera);
        // this.raycaster.layers.enableAll()

        let intersects = this.raycaster.intersectObjects(this.intersectObjs, true);
        this.dispatchEvent({
            type: 'move',
            intersects
        });

        if (intersects.length > 0) {

        } else {

        }
    }
    checkIntersection_dblclick(c = 10) {
        this.raycaster.setFromCamera(this.mouse, this.camera);
        let intersects = this.raycaster.intersectObjects([this.scene], true);
        intersects = intersects.filter(x => x.object.visible)
        this.dispatchEvent({
            type: 'dbclick',
            intersects
        });
        if (intersects.length > 0) {
            // Commen.go_cameraV(intersects[0].point, new THREE.Vector3(intersects[0].point.x + c, intersects[0].point.y + c, intersects[0].point.z + c), this.controls, this.camera, 1, 0);
        }
    }

    onMouseDrag(e) {

        if (!this.isMouseDown) return;

        this.dispatchEvent({
            type: 'drag',
            event: e
        });
    }
}


function throttle(fn, delay, mustRunDelay) {
    var timer = null;
    var t_start;
    return function () {
        var context = this,
            args = arguments,
            t_curr = +new Date();
        cancelAnimationFrame(timer);
        if (!t_start) {
            t_start = t_curr;
        }
        if (t_curr - t_start >= mustRunDelay) {
            fn.apply(context, args);
            t_start = t_curr;
        } else {
            timer = requestAnimationFrame(function () {
                fn.apply(context, args);
            }, delay);
        }
    };
}

export {
    EventHandler
};