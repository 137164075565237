<template>
  <div class="home">
    <div id="mse">
      <video
        id="video"
        loop
        webkit-playsinline
        playsinline
        preload="auto"
        x-webkit-airplay="true"
        x5-playsinline="true"
        x5-video-player-type="h5"
        ref="myVideo"
      >
        <!-- <source :src="theearth" type="video/mp4" /> -->
        <source :src="url1" type="video/mp4" />
      </video>
      <div class="jiundutiao"></div>
      <!-- <div class="fanhui" @click="fanhuibtn">
        <img
          :src="
            xuanzhong == '第一章' || xuanzhong == '第三章' ? fanhui1 : fanhui2
          "
          alt=""
        />
      </div> -->
    </div>
    <div class="playbtn" @click="btn()" v-if="!drawershow">
      <img :src="videostate ? zanting : bofang" alt="" />
    </div>
    <div class="home_center">
      <div class="top_text" v-if="!drawershow">
        <div class="top_text_son">
          <a href="https://testnet.myweber.org/">{{
            language ? "浏览器" : "Explorer"
          }}</a>
        </div>
        <div class="top_text_son">
          <a>{{ language ? "提供" : "Provide" }}</a>
        </div>
        <div class="top_text_son">
          <a>{{ language ? "开发" : "Build" }}</a>
        </div>
        <div class="top_text_son">
          <a>{{ language ? "博客" : "Blog" }}</a>
        </div>
      </div>

      <div class="top_yuyan" @click="btnclick()" v-if="!drawershow">
        <p>EN</p>
        <p>中文</p>
      </div>
      <div class="yuyan" v-if="drawershow">
        <img
          @click="unfold2()"
          :src="
            xuanzhong == '第一章' || xuanzhong == '第三章'
              ? xuanxiang2
              : xuanxiang
          "
          alt=""
        />
      </div>
      <el-drawer
        :visible.sync="drawer"
        :direction="direction"
        :modal-append-to-body="false"
        :wrapperClosable="true"
      >
        <div class="drawer_title">
          <p>{{ language ? "存储" : "Store" }}</p>
        </div>
      </el-drawer>
    </div>
    <div class="bottom_box">
      <div class="bottom_box_top">
        <p id="text_animation">
          {{
            language
              ? "在过去300年的工业革命里，技术和科学的进步产生了大量的工具。"
              : "Over the past 300 years of the Industrial Revolution, technological and scientific advances have produced avast array of tools"
          }}
        </p>
      </div>
      <div class="bottom_box_bottom">
        <div
          :class="
            xuanzhong == item.name
              ? 'bottom_box_bottom_sonopt'
              : 'bottom_box_bottom_son'
          "
          v-for="(item, index) in list"
          :key="index"
          @click="switchover(item.name)"
          v-if="!drawershow"
        >
          <p>{{ language ? item.name : item.enname }}</p>
          <p>{{ language ? item.text : item.entext }}</p>
        </div>
        <div
          :id="item.id"
          :class="
            xuanzhong == item.name
              ? 'bottom_box_bottom_sonopt'
              : 'bottom_box_bottom_son'
          "
          v-for="(item, index) in list"
          :key="index"
          v-if="drawershow && xuanzhong == item.name"
        >
          <p>{{ item.name }}</p>
          <p>{{ item.text }}</p>
        </div>
        <div class="jiantou" v-if="drawershow">
          <img @click="zuo()" src="../assets/image/zuojiantou1.png" alt="" />
          <img @click="you()" src="../assets/image/youjiantou1.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Player from "xgplayer";
import "xgplayer/dist/index.min.css";
import bofang from "@/assets/image/bofang.png";
import zanting from "@/assets/image/zanting.png";
import xuanxiang from "@/assets/image/xuanxiang.png";
import xuanxiang2 from "@/assets/image/xuanxiang2.png";
import fanhui1 from "@/assets/image/fanhui1.png";
import axios from "axios";
export default {
  data() {
    return {
      player: null,
      bofang: bofang,
      zanting: zanting,
      videostate: true,
      list: [
        {
          name: "第一章",
          text: "Weber链展望",
          enname: "chapter one",
          entext: "Weber chain outlook",
          id: "cs1",
        },
        {
          name: "第二章",
          text: "数据自主权",
          enname: "chapter two",
          entext: "Data autonomy",
          id: "cs2",
        },
        {
          name: "第三章",
          text: "能源调度权",
          enname: "chapter three",
          entext: "Power of energy dispatching",
          id: "cs3",
        },
        {
          name: "第四章",
          text: "应用控制权",
          enname: "chapter four",
          entext: "Application control",
          id: "cs4",
        },
      ],
      xuanzhong: "第一章",
      video: null,
      drawershow: false,
      screenWidth: document.body.offsetWidth,
      drawer: false,
      direction: "rtl",
      language: true,
      xuanxiang: xuanxiang,
      xuanxiang2: xuanxiang2,
      fanhui1: fanhui1,
      p: null,
      // blobSrc: video.video[0].url,
      url1: "",
    };
  },
  created(){
  
  },
  mounted() {
    this.video = document.getElementById("video");
      this.p = document.getElementById("text_animation");
      this.video.addEventListener("timeupdate", this.addevent, false);
      window.onresize = () => {
        this.screenWidth = document.body.offsetWidth;
      };
      let that = this;
    new Promise((resolve, reject) => {
      axios
        .get("video/video.json", {})
        .then((response) => {
          that.url1 = response.data.video[0].url;
          this.video.src=that.url1;
          console.log(response);
          // if (this.url.includes("..")) {
          // }
          // this.downloadAndPlayVideo();
          this.$refs.myVideo.addEventListener("loadedmetadata", () => {
            console.log("视频元数据已加载，但请注意这不是全部下载完成的信号");
            // 由于我们已经有了整个Blob，这里可以认为视频已经“全部下载完成”
            // 执行您需要的操作，比如显示一个消息或开始播放视频
            setTimeout(() => {
              this.$emit("jiazaizhongclick", false);
            }, 2000);
          });
          resolve();
        })
        .catch(function (error) {
          console.log(error);
        });
    }).then(() => {
      
    });
  },
  methods: {
    downloadAndPlayVideo() {
      fetch(this.url1)
        .then((response) => response.blob())
        .then((blob) => {
          // 创建一个URL对象
          const url = URL.createObjectURL(blob);

          // 更新video元素的src属性
          this.$refs.myVideo.src = url;

          // 视频加载完成后（这里其实不太需要，因为我们已经有了整个Blob）
          // 但可以监听canplay或loadedmetadata等事件来确保视频元数据已加载
          this.$refs.myVideo.addEventListener("loadedmetadata", () => {
            console.log("视频元数据已加载，但请注意这不是全部下载完成的信号");
            // 由于我们已经有了整个Blob，这里可以认为视频已经“全部下载完成”
            // 执行您需要的操作，比如显示一个消息或开始播放视频
            setTimeout(() => {
              this.$emit("jiazaizhongclick", false);
            }, 2000);
          });

          // // 记住在不需要时释放URL对象
          // this.$once('hook:beforeDestroy', () => {
          //   URL.revokeObjectURL(url);
          // });
        })
        .catch((error) => {
          console.error("下载视频时出错:", error);
        });
    },
    blob_load(src) {
      const req = new XMLHttpRequest();
      req.open("GET", src, true);
      req.responseType = "blob";
      req.onload = (e) => {
        // Onload is triggered even on 404
        // so we need to check the status code
        console.log(e);
        if (e.currentTarget.status === 200) {
          const videoBlob = e.currentTarget.response;
          this.blobSrc = URL.createObjectURL(videoBlob); // IE10+
          console.log(this.status);
          console.log(this.response);

          // Video is now downloaded
          // and we can set it as source on the video element
          // video.src = blobSrc ;
          this.$emit("jiazaizhongclick", false);
          // console.log(blobSrc, 'blobSrc加载完毕');
        }
      };
      req.onerror = function () {
        // Error
      };
      req.send();
    },
    addevent() {
      document.querySelector(".jiundutiao").style.width =
        (Math.floor(this.video.currentTime) / 218) * 100 + "%";
      switch (Math.floor(this.video.currentTime)) {
        case 2: {
          this.p.classList = "text_animation";
          this.aaa("第一章");
          this.nextdom("white");
          break;
        }
        case 3: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 4: {
          this.p.innerHTML = this.language
            ? "而工具也进一步促进了人类科技的发展。"
            : "And tools have further promoted the development of human technology.";
          this.p.classList = "text_animation";
          break;
        }
        case 5: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 6: {
          this.p.innerHTML = this.language
            ? "但是现在，当人工智能取得了飞速的突破"
            : "But now, when artificial intelligence has made rapid breakthroughs";
          this.p.classList = "text_animation";
          break;
        }
        case 7: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 8: {
          this.p.innerHTML = this.language
            ? "价值创造可以直接依赖于人工智能或者说极依赖于少数人"
            : "Value creation can depend directly on AI or heavily on a few people";
          this.p.classList = "text_animation";
          break;
        }
        case 9: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 10: {
          this.p.innerHTML = this.language
            ? "人工智能不仅仅会让大多数人丢失工作"
            : "Ai won't just put most people out of work";
          this.p.classList = "text_animation";
          break;
        }
        case 11: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 12: {
          this.p.innerHTML = this.language
            ? "当大数据算法超过我们自身的生物算法时，决定权就会由人类转为算法"
            : "When big data algorithms outpace our own biological algorithms, the decision will shift from humans to algorithms";
          this.p.classList = "text_animation";
          break;
        }
        case 13: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 14: {
          this.p.innerHTML = this.language
            ? "人类几乎无法战胜人工智能带来的科技挑战"
            : "It is almost impossible for humans to overcome the technological challenges posed by artificial intelligence";
          this.p.classList = "text_animation";
          break;
        }
        case 15: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 16: {
          this.p.innerHTML = this.language
            ? "Weber链致力于建能够实现全球协作的去中心化组织，加强全球合作"
            : "Weber Chain is committed to building a decentralized organization that can achieve global collaboration and strengthen global cooperation";
          this.p.classList = "text_animation";
          break;
        }
        case 17: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 18: {
          this.p.innerHTML = this.language
            ? "利用区块链技术和思想重塑生产关系，共同应对人类社会即将面临的各种挑战"
            : "Use blockchain technology and ideas to reshape production relations and jointly deal with various upcoming challenges facing human society";
          this.p.classList = "text_animation";
          break;
        }
        case 19: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 21: {
          this.p.innerHTML = this.language
            ? "数据已经成为社会发展的重要生产资料"
            : "Identity system is the key to standardize data ownership";
          this.p.classList = "text_animation";
          this.aaa("第二章");
          this.nextdom("black");
          break;
        }
        case 24: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 26: {
          this.p.innerHTML = this.language
            ? "它也是人工智能发展的基础"
            : "It is also the basis for the development of artificial intelligence";
          this.p.classList = "text_animation";
          break;
        }
        case 29: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 31: {
          this.p.innerHTML = this.language
            ? "规范数据的所有权，是应对挑战的关键"
            : "Regulating the ownership of data is key to meeting the challenge";
          this.p.classList = "text_animation";
          break;
        }
        case 34: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          this.nextdom("white");
          break;
        }
        case 36: {
          this.p.innerHTML = this.language
            ? "Weber治理链通过People transfer token（人类转移通证）激励人们加入网络并为所有加入网络中的人创建身份体系"
            : "The Weber governance chain incentivizes People to join the network through People transfer Tokens And create an identity system for everyone who joins the network";
          this.p.classList = "text_animation";
          break;
        }
        case 76: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          this.nextdom("black");
          break;
        }
        case 78: {
          this.p.innerHTML = this.language
            ? "身份体系是规范数据所有权的关键"
            : "Identity system is the key to standardize data ownership";
          this.p.classList = "text_animation";
          break;
        }
        case 86: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 88: {
          this.p.innerHTML = this.language
            ? "投票协议可以控制数据应用的和Weber网络发展"
            : "Voting protocols can control the development of data applications and Weber networks";
          this.p.classList = "text_animation";
          break;
        }
        case 93: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 95: {
          this.p.innerHTML = this.language
            ? "治理链可以让人们能够实现全球紧密合作的"
            : "The chain of governance allows people to work closely together globally";
          this.p.classList = "text_animation";
          break;
        }
        case 100: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          this.nextdom("white");
          break;
        }
        case 102: {
          this.p.innerHTML = this.language
            ? "同时也取得对数据应用的控制权。"
            : "It is truly a network that belongs to each individual";
          this.p.classList = "text_animation";
          break;
        }
        case 119: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 121: {
          this.p.innerHTML = this.language
            ? "人工智能的发展高度依赖能源和算力"
            : "The development of artificial intelligence is highly dependent on energy and computing power";
          this.p.classList = "text_animation";
          this.aaa("第三章");
          this.nextdom("black");
          break;
        }
        case 129: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }

        case 131: {
          this.p.innerHTML = this.language
            ? "Weber能源链通过工作量证明机制激励能源和算力加入网络"
            : "The Weber Energy Chain incentivizes energy and computing power to join the network through a proof-of-work mechanism";
          this.p.classList = "text_animation";
          break;
        }
        case 139: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 141: {
          this.p.innerHTML = this.language
            ? "但随着网络激励的降低个人无法降低运维成本"
            : "However, with the reduction of network incentives, individuals cannot reduce operation and maintenance costs";
          this.p.classList = "text_animation";
          break;
        }
        case 143: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 145: {
          this.p.innerHTML = this.language
            ? "能源和算力将会越来越集中化，网络也会被少数人控制"
            : "Energy and computing power will become increasingly centralized, and networks will be controlled by a few people";
          this.p.classList = "text_animation";
          break;
        }
        case 146: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 147: {
          this.p.innerHTML = this.language
            ? "这和去中心化的思维相背驰"
            : "This goes against the grain of decentralized thinking";
          this.p.classList = "text_animation";
          break;
        }
        case 149: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }

        case 151: {
          this.p.innerHTML = this.language
            ? "Weber能源链的控制权不是有算力的多少控制"
            : "It is not determined by the amount of computing power or the amount of money";
          this.p.classList = "text_animation";
          break;
        }
        case 154: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 156: {
          this.p.innerHTML = this.language
            ? "而是由治理链的所有用户投票控制"
            : "It is controlled by the votes of all users of the governance chain";
          this.p.classList = "text_animation";
          break;
        }
        case 159: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }

        case 161: {
          this.p.innerHTML = this.language
            ? "这就给建立DEFI提供了更合适的基础环境"
            : "This provides a more suitable basic environment for DEFI establishment";
          this.p.classList = "text_animation";

          break;
        }
        case 164: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 166: {
          this.p.innerHTML = this.language
            ? "它不是由算力的多少或者资金的多少而决定网络"
            : "It is not determined by the amount of computing power or the amount of money";
          this.p.classList = "text_animation";
          break;
        }
        case 168: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 169: {
          this.p.innerHTML = this.language
            ? "是真正意义上的属于每个个人的网络"
            : "It is truly a network that belongs to each individual";
          this.p.classList = "text_animation";
          break;
        }
        case 172: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          this.nextdom("white");
          break;
        }

        case 174: {
          this.p.innerHTML = this.language
            ? "去中心化的应用的发展对于网络效率要求越来越高"
            : "The development of decentralized applications requires more and more network efficiency";
          this.p.classList = "text_animation";
          this.aaa("第四章");
          this.nextdom("white");
          break;
        }
        case 181: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 183: {
          this.p.innerHTML = this.language
            ? "在过往的探索中，去中心化和效率总是对立的"
            : "In past explorations, decentralization and efficiency have always been antithetical";
          this.p.classList = "text_animation";
          break;
        }
        case 188: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 190: {
          this.p.innerHTML = this.language
            ? "Weber智能链本身将网络发展控制权交给治理链"
            : "The Weber Intelligent chain itself gives control of network development to the governance chain";
          this.p.classList = "text_animation";
          break;
        }
        case 194: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 196: {
          this.p.innerHTML = this.language
            ? "智能链只注重安全和效率两部分"
            : "Smart chain only focuses on two parts: safety and efficiency";
          this.p.classList = "text_animation";
          break;
        }
        case 200: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 202: {
          this.p.innerHTML = this.language
            ? "应用是产生数据和处理数据的载体"
            : "Application is the carrier of data generation and data processing";
          this.p.classList = "text_animation";
          break;
        }
        case 206: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 208: {
          this.p.innerHTML = this.language
            ? "治理链会为智能链的发展提供用户和数据支持"
            : "The governance chain will provide user and data support for the development of the smart chain";
          this.p.classList = "text_animation";
          break;
        }
        case 213: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
        case 215: {
          this.p.innerHTML = this.language
            ? "能源链则会为智能链的发展提供数据处理的基础条件"
            : "The energy chain will provide the basic conditions for data processing for the development of the intelligent chain";
          this.p.classList = "text_animation";
          break;
        }
        case 218: {
          this.p.classList.remove("text_animation");
          this.p.classList = "text_animation2";
          break;
        }
      }
    },
    
    btn() {
      let video = document.getElementById("video");
      if (this.videostate) {
        video.pause();
        this.videostate = false;
      } else {
        video.play();
        this.videostate = true;
      }
    },
    switchover(e) {
      this.xuanzhong = e;
      switch (e) {
        case "第一章": {
          this.video.currentTime = 2;
          this.nextdom("white");
          break;
        }
        case "第二章": {
          this.video.currentTime = 21;
          this.nextdom("black");
          break;
        }
        case "第三章": {
          this.video.currentTime = 121;
          this.nextdom("black");
          break;
        }
        case "第四章": {
          this.video.currentTime = 174;
          this.nextdom("white");
          break;
        }
      }
    },
    aaa(e) {
      this.xuanzhong = e;
    },
    nextdom(value) {
      switch (value) {
        case "white": {
          this.$nextTick(() => {
            let bottom_box = document.querySelector(
              ".bottom_box_bottom_sonopt"
            );
            let bottom_boxs = document.querySelectorAll(
              ".bottom_box_bottom_son"
            );
            let text_animation = document.getElementById("text_animation");
            bottom_box.style.color = "white";
            bottom_boxs.forEach((item) => {
              item.style.color = "rgb(157, 159, 163)";
            });
            text_animation.style.color = "white";
          });
          break;
        }
        case "black": {
          this.$nextTick(() => {
            let bottom_box = document.querySelector(
              ".bottom_box_bottom_sonopt"
            );
            let bottom_boxs = document.querySelectorAll(
              ".bottom_box_bottom_son"
            );
            let text_animation = document.getElementById("text_animation");
            bottom_box.style.color = "black";
            bottom_boxs.forEach((item) => {
              item.style.color = "#535050";
            });
            text_animation.style.color = "black";
          });
          break;
        }
      }
    },
    play() {
      let video = document.getElementById("video");
      video.play();
    },
    unfold2() {
      this.drawer = true;
    },
    zuo() {
      switch (this.xuanzhong) {
        case "第一章": {
          this.switchover("第四章");
          break;
        }
        case "第二章": {
          this.switchover("第一章");
          break;
        }
        case "第三章": {
          this.switchover("第二章");
          break;
        }
        case "第四章": {
          this.switchover("第三章");
          break;
        }
      }
    },
    you() {
      switch (this.xuanzhong) {
        case "第一章": {
          this.switchover("第二章");
          break;
        }
        case "第二章": {
          this.switchover("第三章");
          break;
        }
        case "第三章": {
          this.switchover("第四章");
          break;
        }
        case "第四章": {
          this.switchover("第一章");
          break;
        }
      }
    },
    fanhuibtn() {
      this.$parent.levelshow = false;
      this.$parent.thre = true;
      this.$parent.over();
      this.video.pause();
    },
    close() {
      this.video.removeEventListener("timeupdate", this.addevent, false);
    },
    btnclick() {
      this.language = !this.language;
      this.$emit("ch_en", this.language);
    },
  },
  watch: {
    screenWidth: {
      immediate: true,
      handler(newvalue) {
        if (newvalue <= 900) {
          this.drawershow = true;
        } else {
          this.drawershow = false;
        }
      },
    },
  },
  beforeDestroy() {
    this.close();
  },
};
</script>
<style lang="less">
a:link {
  text-decoration: none;
  color: rgb(102, 103, 104);
}
a:visited {
  color: rgb(102, 103, 104);
  text-decoration: none;
}
.home {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  animation: identifier 2s;
}

#mse {
  width: 100%;
  height: 100%;
  position: absolute;
  //   pointer-events: none;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: auto;
  position: absolute;
  z-index: 0;
}

#mse::before {
  content: "";
  width: 100%;
  height: 100%;
}

.playbtn {
  width: 1.7vw;
  height: 1.7vw;
  position: absolute;
  bottom: 3vh;
  left: 2vw;
  border-radius: 100%;
  display: flex;
  background-color: #47474769;
  pointer-events: auto;
  z-index: 2;
  cursor: pointer;

  img {
    width: 40%;
    margin: auto;
  }
}

.fanhui {
  width: 1.7vw;
  height: 1.7vw;
  position: absolute;
  top: 10vh;
  left: 2.4vw;
  border-radius: 100%;
  display: flex;
  background-color: #47474769;
  pointer-events: auto;
  z-index: 2;
  cursor: pointer;

  img {
    width: 40%;
    margin: auto;
  }
}

/deep/ .xgplayer .xgplayer-start {
  display: none;
}

.home_center {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}

@keyframes animat {
  0% {
    opacity: 0;
    top: -5vh;
    // transform: translate(0px, -5vh);
  }

  100% {
    opacity: 1;
    top: 2vh;
    // transform: translate(0px,0px);
  }
}

.top_text {
  width: 18vw;
  height: 5vh;
  position: relative;
  left: 15vw;
  display: flex;
  top: -5vh;
  // justify-content: space-around;
  animation: animat 0.3s 1s linear forwards;
}

.top_text_son {
  width: auto;
  height: 100%;
  margin-top: 2vh;
  margin-left: 2vw;
  p {
    font-size: 0.6vw;
    color: rgb(103, 104, 105);
    font-weight: bold;
    cursor: pointer;
    font-weight: bold;
  }
  p:hover {
    color: #fff;
  }
  a {
    font-size: 0.78vw;
    color: rgb(103, 104, 105);
    font-weight: bold;
    cursor: pointer;
    font-weight: bold;
    padding: 0;
  }
  a:hover {
    color: #fff;
  }
}

.top_yuyan {
  width: 5vw;
  height: 5vh;
  position: absolute;
  right: 2.5vw;
  top: -4vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 2;
  animation: animat 0.3s 1s linear forwards;
  p {
    font-size: 0.8vw;
    color: rgb(103, 104, 105);
    font-weight: bold;
    pointer-events: auto;
  }
  p:hover {
    font-size: 0.8vw;
    color: rgb(15, 15, 15);
    font-weight: bold;
    cursor: pointer;
    pointer-events: auto;
  }
}

.bottom_box {
  width: 25vw;
  height: 30vh;
  position: absolute;
  bottom: -4vh;
  left: 12vw;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .bottom_box_top {
    width: 100%;
    height: 60%;
    position: relative;
    #text_animation {
      font-size: 1.5vw;
      color: #fff;
      margin: 0;
      margin-left: 3vw;
      opacity: 0;
      position: absolute;
      bottom: 0;
      margin-bottom: 5vh;

      // animation: identifier 2s;
    }

    .text_animation {
      animation: identifier 1.5s forwards;
    }

    .text_animation2 {
      animation: identifier2 1.5s forwards;
    }
  }

  .bottom_box_bottom {
    width: 25vw;
    height: 40%;
    display: flex;
    opacity: 0;
    animation: identifier 0.3s 1s linear forwards;
    .bottom_box_bottom_son {
      width: auto;
      height: 100%;
      font-size: 0.7vw;
      margin-left: 3vw;
      cursor: pointer;
      color: rgb(157, 159, 163);
      p {
        margin: 0;
        white-space: nowrap;
      }
      // opacity: 0;
    }

    .bottom_box_bottom_sonopt {
      width: auto;
      height: 100%;
      font-size: 0.7vw;
      margin-left: 3vw;
      cursor: pointer;
      color: #fff;
      transition: all 0.5s;
      p {
        margin: 0;
        font-weight: bold;
        white-space: nowrap;
      }
      // opacity: 0;
    }

    .bottom_box_bottom_son:hover {
      color: #fff;
    }
  }
}

@keyframes identifier {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes identifier2 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.jiundutiao {
  width: 0vw;
  height: 0.5vh;
  background-color: aqua;
  position: absolute;
  bottom: 0vh;
  left: 0;
  transition: all 0.5s;
}

@media screen and(max-width: 900px) {
  .yuyan {
    width: 5vw;
    height: 3vh;
    display: flex;
    justify-content: space-around;
    position: absolute;
    right: 5vw;
    top: 3vh;

    img {
      width: 100%;
      cursor: pointer;
      pointer-events: auto;
    }
  }

  /deep/.el-drawer__container {
    pointer-events: auto !important;
  }

  /deep/.el-drawer {
    width: 80% !important;
  }

  /deep/.el-drawer__body {
    overflow-x: hidden;
  }

  .drawer_title {
    width: 70vw;
    height: 8vw;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    margin: auto;
    animation: instep 2s;

    p {
      margin: 0;
      font-size: 2.5vh;
      color: black;
      font-weight: 500;
      position: absolute;
      bottom: 1vh;
      left: 2vw;
    }
  }

  .bottom_box {
    width: 85vw;
    height: 20vh;
    position: absolute;
    bottom: 5vh;
    left: 50%;
    transform: translateX(-50%);

    .bottom_box_top {
      width: 100%;
      height: 60%;
      border-bottom: 2px solid rgba(255, 255, 255, 0.1);
      display: flex;

      #text_animation {
        font-size: 2.5vh;
        color: #fff;
        margin: 0;
        margin: auto;
        margin-left: 1vw;
        opacity: 0;
        // animation: identifier 2s;
        transition: all 0.5s;
      }

      .text_animation {
        animation: identifier 1.5s forwards;
      }

      .text_animation2 {
        animation: identifier2 1.5s forwards;
      }
    }

    .bottom_box_bottom {
      width: 100%;
      height: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      opacity: 0;
      animation: identifier 0.3s 1s linear forwards;
      overflow: hidden;
      display: flex;
      position: relative;

      .bottom_box_bottom_son {
        width: auto;
        height: 100%;
        font-size: 1vh;
        margin-left: 1vw;
        cursor: pointer;
        color: rgb(157, 159, 163);
        transition: all 0.5s;
        // opacity: 0;
      }

      .bottom_box_bottom_sonopt {
        width: auto;
        height: 100%;
        font-size: 1.5vh;
        margin-left: 1vw;
        cursor: pointer;
        color: #fff;
        transition: all 0.5s;
        // opacity: 0;
      }

      .bottom_box_bottom_son:hover {
        color: #fff;
      }

      .jiantou {
        width: 15vh;
        position: absolute;
        right: 2vw;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 7vh;
        }
      }
    }
  }
}
</style>
