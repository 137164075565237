<template>
  <div id="box" class="box" style="width: 100vw; height: 100vh">
    <div
      style="
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 0;
        pointer-events: none;
      "
    >
      <three ref="three" v-if="thre" />
    </div>
    <div class="full-box">
      <div class="top_box">
        <div class="tubiao" @click="fanhui()">
          <img src="@/assets/image/logo.png" alt="" />
        </div>
        <div class="yuyan" v-if="!drawershow">
          <p @click="btn()">EN</p>
          <p @click="btn()">中文</p>
        </div>
        <div class="yuyan" v-if="drawershow">
          <img @click="unfold()" src="../assets/image/xuanxiang.png" alt="" />
        </div>
        <el-drawer
          :visible.sync="drawer"
          :direction="direction"
          :modal-append-to-body="false"
          :wrapperClosable="true"
        >
          <div class="drawer_title">
            <a href="https://mainnet.myweber.org/" target="_blank">{{ language ? "浏览器" : "Explorer" }}</a>
          </div>
          <div class="drawer_title">
            <a href="./WeberGC-Whitepaper V1.pdf" target="_blank">{{ language ? "白皮书" : "white paper" }}</a>
          </div>
          <div class="drawer_title">
            <a>{{ language ? "开发" : "Build" }}</a>
          </div>
          <div class="drawer_title">
            <a href="https://blog.myweber.org/" target="_blank">{{ language ? "博客" : "Blog" }}</a>
          </div>
          <div class="drawer_title2">
          <p @click="btn()">EN</p>
          <p @click="btn()">中文</p>
        </div>
        </el-drawer>
      </div>
      <div class="middle_box">
        <div class="son">
          <a href="https://mainnet.myweber.org/" target="_blank">{{ language ? "浏览器" : "Explorer" }}</a>
        </div>
        <div class="son">
          <a href="./WeberGC-Whitepaper V1.pdf" target="_blank">{{ language ? "白皮书" : "white paper" }}</a>
        </div>
        <div class="son">
          <a>{{ language ? "开发" : "Build" }}</a>
        </div>
        <div class="son">
          <a href="https://blog.myweber.org/" target="_blank">{{ language ? "博客" : "Blog" }}</a>
        </div>
      </div>
      <div class="text">
        <div class="text_son" >
          <span style="font-weight: 500;">Weber</span>
          {{
            language
              ? "致力于利用区块链技术解决当今社会面临的科技挑战"
              : "is committed to using blockchain technology to solve the technological challenges facing society today"
          }}
        </div>
        <div class="upload">
          <div
            @click="start()"
            class="upload_son"
            id="upload_son"
            v-if="!ione && !drawershow"
          >
            <!-- <img src="../assets//image/intro-play.svg" alt=""> -->
            <svg
              viewBox="0 0 50 50"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              v-if="!jiazaizhong"
            >
              <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
              <title>Group 2 Copy 2</title>
              <desc>Created with Sketch.</desc>
              <defs>
                <linearGradient
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="100%"
                  id="linearGradient-1"
                >
                  <stop :stop-color="aaa" :offset="bbb"></stop>
                  <stop stop-color="#295EFF" offset="100%"></stop>
                </linearGradient>
                <linearGradient
                  x1="-90.2218163%"
                  y1="-113.775635%"
                  x2="180.361047%"
                  y2="204.736328%"
                  id="linearGradient-2"
                >
                  <stop stop-color="#43C1CA" offset="0%"></stop>
                  <stop stop-color="#1D0EB1" offset="100%"></stop>
                </linearGradient>
              </defs>
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g id="play" fill-rule="nonzero">
                  <g id="Group-2-Copy-2">
                    <circle
                      id="Oval"
                      stroke="url(#linearGradient-1)"
                      stroke-width="2"
                      cx="25"
                      cy="25"
                      r="24"
                    ></circle>
                    <polygon
                      id="Path"
                      fill="url(#linearGradient-2)"
                      points="32.5 25 20.8333333 17.5 20.8333333 32.5"
                    ></polygon>
                  </g>
                </g>
              </g>
            </svg>
            <p v-if="!jiazaizhong">
              {{ language ? "探索Weber愿景" : "Explore the Weber Vision" }}
            </p>
            <div class="line" v-if="jiazaizhong"></div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
    <level
      ref="video"
      v-show="levelshow"
      @ch_en="ch_en"
      @jiazaizhongclick="jiazaizhongclick"
    ></level>
    <!-- <div class="full-box-fill"></div> -->
    <div class="bottom-box">
      <div class="box_son">
        <div class="bottom-box-son1">
          <p>
            {{
              language
                ? "Weber致力于利用区块链技术解决当今社会面临的科技挑战"
                : "Weber is committed to using blockchain technology to solve the technological challenges facing society today"
            }}
          </p>
        </div>
        <div class="bottom-box-son2">
          <div class="box-son2_title">
            <p>{{ language ? "联系我们" : "Reach out" }}</p>
          </div>
          <div class="box-son2_center">
            <a href="">{{ language ? "推特号" : "Twitter" }}</a>
            <a>{{ language ? "电报" : "Telegram" }}</a>
            <a>{{ language ? "邮件" : "Mail" }}</a>
          </div>
        </div>
        <div class="bottom-box-son2">
          <div class="box-son2_title">
            <p>{{ language ? "链接资源" : "Resources" }}</p>
          </div>
          <div class="box-son2_center">
            <a>{{ language ? "GitHub" : "GitHub" }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import three from "../components/three/three.vue";
import level from "../components/level.vue";
export default {
  name: "Home",
  components: {
    three,
    level,
  },
  data() {
    return {
      input: "",
      language: true,
      drawershow: false,
      direction: "rtl",
      drawer: false,
      screenWidth: document.body.offsetWidth,
      aaa: "#43C1CA",
      bbb: "0%",
      levelshow: false,
      thre: true,
      ione: false,
      jiazaizhong: true,
    };
  },

  mounted() {
    window.onresize = () => {
      this.screenWidth = document.body.offsetWidth;
    };
    let dom = document.getElementById("upload_son");
    dom.addEventListener("mouseover", () => {
      this.aaa = "#0bf7ff";
      this.bbb = "15%";
    });
    dom.addEventListener("mouseout", () => {
      this.aaa = "#43C1CA";
      this.bbb = "0%";
    });
    this.pone();
  },
  watch: {
    screenWidth: {
      immediate: true,
      handler(newvalue) {
        if (newvalue <= 900) {
          this.drawershow = true;
        } else {
          this.drawershow = false;
        }
      },
    },
    language: {
      handler(newvalue) {
        this.$refs.video.language = newvalue;
      },
    },
  },
  beforeUnmount() {},
  methods: {
    ch_en(e) {
      this.language = e;
    },
    btn() {
      this.language = !this.language;
    },
    unfold() {
      this.drawer = true;
    },
    addeventanim() {
      let count = 0;
      const element = document.getElementById("animated-element");
    },
    start() {
      let cunchu = document.querySelectorAll(".son");
      cunchu.forEach((item) => {
        item.classList.remove("son_show");
        item.classList.add("son_tran");
      });

      let zhongyingqiehuan = document.querySelector(".yuyan");
      zhongyingqiehuan.classList.remove("son_show");
      zhongyingqiehuan.classList.add("son_tran");
      let text = document.querySelector(".text");
      text.classList.remove("text_show2");
      text.classList.add("text_tran2");
      this.$refs.video.play();
      this.thre = false;
      this.levelshow = true;
    },
    over() {
      let cunchu = document.querySelectorAll(".son");
      cunchu.forEach((item) => {
        item.classList.remove("son_tran");
        item.classList.add("son_show");
      });

      let zhongyingqiehuan = document.querySelector(".yuyan");
      zhongyingqiehuan.classList.remove("son_tran");
      zhongyingqiehuan.classList.add("son_show");
      let text = document.querySelector(".text");
      text.classList.remove("text_tran2");
      text.classList.add("text_show2");
    },
    pone() {
      let isMobile = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      isMobile = isMobile ? 2 : 1;
      if (isMobile == 1) {
        this.ione = false;
      } else {
        this.ione = true;
      }
    },
    jiazaizhongclick(e) {
      this.jiazaizhong = e;
    },
    fanhui() {
      if (this.levelshow) {
        this.$refs.video.fanhuibtn();
      }
    },
  },
};
</script>
<style scoped lang="less">
a:link {
  text-decoration: none;
  color: rgb(102, 103, 104);
}
a:visited {
  color: rgb(102, 103, 104);
  text-decoration: none;
}
.son_tran {
  animation: tran 1s;
  opacity: 0;
}
.text_tran2 {
  animation: tran2 1s;
  opacity: 0;
}
.son_show {
  animation: show 1s;
  opacity: 1;
}
.text_show2 {
  // animation: show2 2s;
  animation: color 2s;
  opacity: 1;
}

@keyframes tran {
  from {
    opacity: 1;
    // bottom: 0;
    transform: translate(0px, 0px);
  }

  to {
    opacity: 0;
    // bottom: 17.2vh;
    transform: translate(0px, -5vh);
  }
}
@keyframes tran2 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
@keyframes show {
  from {
    opacity: 0;
    // bottom: 0;
    transform: translate(0px, -5vh);
  }

  to {
    opacity: 1;
    // bottom: 17.2vh;
    transform: translate(0px, 0px);
  }
}
@keyframes show2 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.upload {
  width: 3vw;
  height: 5vh;
  margin-top: 5vh;
  animation: opac 5s;
  .line {
    position: absolute;
    animation: loader 2000ms infinite linear;
    border-radius: 100%;
    border: 0.05vw solid #43c1ca;
    border-left-color: transparent;
    color: transparent;
    display: inline-block;
    width: 5.2vh;
    height: 5.2vh;
    transition: all 0.5s;
  }
}
.upload_son {
  width: 15vw;
  height: 2vw;
  position: absolute;
  bottom: 3vh;
  pointer-events: auto;
  cursor: pointer;
  display: flex;
  color: #000;
  align-items: center;
  svg {
    margin-left: 0.01vw;
    width: 5.3vh;
    height: 5.3vh;
    animation: opac 2s;
  }
  p {
    margin: auto;
    margin-left: 1vw;
    font-size: 0.8vw;
    font-weight: bold;
    animation: opac 2s;
  }
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.upload_son:hover {
  color: rgb(2, 145, 255);
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes opac {
  0% {
    opacity: 0;
  }
  100% {
    transform: 1;
  }
}
.box {
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: auto;
}
.full-box {
  width: 100vw;
  height: 100vh;
  position: sticky;
  margin: 0;
  top: 0;
  left: 0;
  z-index: 999;
  pointer-events: none;
}

.full-box-fill {
  width: 100vw;
  height: 100vh;
  position: relative;
  top: -100vh;
  left: 0;
  margin: 0;
  z-index: 999;
  pointer-events: auto;
}
.bottom-box {
  width: 100vw;
  height: 35vh;
  background-color: black;
  position: relative;
  z-index: 2;
}
.top_box {
  width: 100%;
  height: 7%;
  position: relative;
}
.tubiao {
  width: 2.3vw;
  height: 2.3vw;
  position: absolute;
  bottom: -0.5vh;
  left: 1.7vw;
  pointer-events: auto;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
.yuyan {
  width: 5vw;
  height: 2vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  right: 2vw;
  bottom: 0;

  p {
    font-size: 0.8vw;
    margin: auto;
    color: #5c5959;
    pointer-events: auto;
  }
  p:hover {
    font-size: 0.8vw;
    margin: auto;
    color: #000000;
    pointer-events: auto;
    font-weight: bold;
  }
}
.middle_box {
  width: 100%;
  height: 15%;
  margin-top: 2vh;
  animation: instep 5s;
}

.son {
  width: 5.5vw;
  height: 5.8vw;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  float: left;
  margin-left: 2vw;
  p {
    margin: 0;
    font-size: 1vw;
    color: black;
    font-weight: 500;
    position: absolute;
    bottom: 0;
    left: 2vw;
  }
  a {
    margin: 0;
    font-size: 1vw;
    color: black;
    font-weight: 500;
    position: absolute;
    bottom: 0;
    cursor: pointer;
    pointer-events: auto;
  }
  a:hover {
    margin: 0;
    font-size: 1vw;
    color: #5fb3f5;
    font-weight: 500;
    position: absolute;
    bottom: 0;
  }
}

.son:nth-child(4) {
  border: none;
}
.sonright {
  width: 8vw;
  height: 8vw;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  float: right;
  margin-right: 5vw;
}

.box_son {
  width: 50%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-around;
}
.bottom-box-son1 {
  width: 15vw;
  p {
    font-size: 1vw;
    color: aliceblue;
    margin-top: 5vh;
  }
}
.bottom-box-son2 {
  width: 10vw;
}
.box-son2_title {
  margin-top: 5vh;
  font-size: 0.8vw;
  color: aliceblue;
}
.box-son3_title {
  margin-top: 5vh;
  font-size: 0.8vw;
  color: aliceblue;
}
.box-son2_center {
  font-size: 0.8vw;
  color: rgb(102, 103, 104);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  a {
    margin-top: 1vh;
  }
}
.bottom-box-son3 {
  width: 15vw;
}
.el-input {
  width: 100%;
}
/deep/.el-input__inner {
  height: 1.5vw;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #dcdfe6;
  font-size: 0.8vw;
}

/deep/.el-input__inner::placeholder {
  color: rgb(102, 103, 104);
}
/* 谷歌 */
/deep/.el-input__inner::-webkit-input-placeholder {
  color: rgb(102, 103, 104);
}
/* 火狐 */
/deep/.el-input__inner:-moz-placeholder {
  color: rgb(102, 103, 104);
}
/*ie*/
/deep/ .el-input__inner:-ms-input-placeholder {
  color: rgb(102, 103, 104);
}
.shurukuang {
  width: 17vw;
  height: auto;
  display: flex;
  img {
    width: 1.5vw;
    height: 1.5vw;
  }
}
@keyframes instep {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes color {
  0% {
    opacity: 0;
    // bottom: 0;
    transform: translate(0px, 5vh);
  }

  100% {
    opacity: 1;
    // bottom: 17.2vh;
    transform: translate(0px, 0px);
  }
}
@font-face {
  font-family: "hhh";
  src: "../assets/css/SuisseIntl-Regular.ttf";
}
.text {
  width: 40vw;
  margin-top: 20vh;
  height: auto;
  position: relative;
  margin-left: 3vw;
  .text_son {
    margin: 0;
    padding: 0;
    display: inline-block;
    font-size: 2.3vw;
    color: black;
    animation: color 2s;
    font-family: "hhh";
  }
}
@media screen and(max-width: 900px) {
  .tubiao {
  width: 8vw;
  height: 8vw;
  position: absolute;
  bottom: -0.5vh;
  left: 1.7vw;
  pointer-events: auto;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
  .full-box {
    width: 100vw;
    height: 140vh;
    position: static;
    margin: 0;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
  }
  .middle_box {
    width: 100%;
    height: 15%;
    margin-top: 2vh;
    position: relative;
    top: 80vh;
  }
  .son {
    width: 90vw;
    height: 8vw;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    p {
      margin: 0;
      font-size: 2.5vh;
      color: black;
      font-weight: 500;
      position: absolute;
      bottom: 1vh;
      left: 2vw;
    }
    a {
      margin: 0;
      font-size: 2.5vh;
      color: black;
      font-weight: 500;
      position: absolute;
      bottom: 1vh;
      left: 2vw;
    }
  }
  .text {
    width: 70vw;
    height: 20%;
    margin-top: 15vh;
    position: relative;
    margin-left: 4vw;
    top: -10vh;
    .text_son {
      margin: 0;
      padding: 0;
      display: inline-block;
      font-size: 4vh;
      color: black;
      animation: color 2s;
      font-family: "hhh";
      font-weight: 500;
    }
  }
  .box_son {
    width: 95%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .bottom-box {
    width: 100vw;
    height: 70vh;
    background-color: black;
    position: relative;
    z-index: 2;
  }
  .bottom-box-son1 {
    width: 78%;
    p {
      font-size: 2.2vh;
      color: aliceblue;
      margin-top: 5vh;
    }
  }
  .bottom-box-son2 {
    width: 22vw;
  }
  .box-son2_title {
    margin-top: 5vh;
    font-size: 1.5vh;
    color: aliceblue;
  }
  .box-son3_title {
    margin-top: 5vh;
    font-size: 2vh;
    color: aliceblue;
  }
  .box-son2_center {
    font-size: 1.5vh;
    color: rgb(102, 103, 104);
    cursor: pointer;
  }
  .bottom-box-son3 {
    width: 80%;
  }
  /deep/.el-input__inner {
    height: 3vh;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #dcdfe6;
    font-size: 1.5vh;
  }
  .shurukuang {
    width: 90%;
    height: auto;
    display: flex;
    img {
      width: 2vh;
      height: 2vh;
    }
  }
  .yuyan {
    width: 5vw;
    height: 3vh;
    display: flex;
    justify-content: space-around;
    position: absolute;
    right: 5vw;
    bottom: 0;
    img {
      width: 100%;
      cursor: pointer;
      pointer-events: auto;
    }
  }
  /deep/.el-drawer__container {
    pointer-events: auto !important;
  }
  /deep/.el-drawer {
    width: 80% !important;
  }
  /deep/.el-drawer__body {
    overflow-x: hidden;
  }
  .drawer_title {
    width: 70vw;
    height: 8vw;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    margin: auto;
    animation: instep 2s;
    p {
      margin: 0;
      font-size: 2.5vh;
      color: black;
      font-weight: 500;
      position: absolute;
      bottom: 1vh;
      left: 2vw;
    }
    a {
      margin: 0;
      font-size: 2.5vh;
      color: black;
      font-weight: 500;
      position: absolute;
      bottom: 1vh;
      left: 2vw;
    }
  }
  .drawer_title2 {
    width: 60vw;
    height: 8vw;
    border: none;
    position: absolute;
    margin: auto;
    animation: instep 2s;
    bottom: 0;
    display: flex;
    margin-left: 3vw;
    p{
      margin-left: 1vw;
      color: #5c5959;
    }
  }
  .upload {
    width: 3vw;
    height: 5vh;
    margin-top: 2vh;
    animation: opac 5s;
  }
  .upload_son {
    width: 50vw;
    height: 5vw;
    position: absolute;
    bottom: 4vh;
    pointer-events: auto;
    cursor: pointer;
    display: flex;
    color: #000;
    align-items: center;
    svg {
      margin-left: 0.01vw;
      width: 4vw;
      height: 4vw;
    }
    p {
      margin: auto;
      margin-left: 1vw;
      font-size: 3vw;
      font-weight: bold;
    }
  }
  .upload_son:hover {
    color: rgb(2, 145, 255);
  }

  .upload > span {
    position: absolute;
    animation: loader 1000ms 5 linear;
    border-radius: 100%;
    border: 0.05vw solid #2dbb55;
    border-left-color: transparent;
    color: transparent;
    display: inline-block;
    width: 4vh;
    height: 4vh;
  }
}
::-webkit-scrollbar {
  width: 1%;
}
</style>
